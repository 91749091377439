import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import './style.scss';

import Logo from '../../../assets/img/logo.png';

const Header = () => {
  return (
    <section className='header'>
        <Navbar collapseOnSelect expand="lg">
          <Link to="/" className="navbar-brend navbar-brand site-branding">
            <img src={Logo} alt="Digital Huri" />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="padding-tb-small"
          >
            <Nav className="mr-auto"></Nav>
            <Nav>
              <Link to="/" className="menu-link nav-link">
                Home
              </Link>
              <Link to="/difference" className="menu-link nav-link">
                Difference
              </Link>
              <Link to="/transformation" className="menu-link nav-link">
                Transformation
              </Link>
              <Link to="/people" className="menu-link nav-link">
                People
              </Link>
              <Link to="/contact" className="menu-link nav-link">
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    </section>
  );
};

export default Header;
