import React from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

import { homePageData } from '../../../content/homePageData';
import Box from '../../atoms/Box';
import HomeStrategyImg from '../../../assets/img/Home-strategy.png';
import HomeTalentImg from '../../../assets/img/Home-talent.png';

const HomeBoxes = () => {
  return (
    <section className="boxes-section">
      <div className="container-fluid">
        <Row>
          <Col className="box yellow-box" sm={12} md={6}>
            <Box
              boxClass="float-right box-text-wrapper"
              imgSrc={HomeStrategyImg}
              imgAlt="Strategy"
              heading={homePageData.box1Heading}
              subheading={homePageData.box1Description}
            />
          </Col>
          <Col className="box box-only-text second-box-width bluish-box" sm={12} md={6}>
            <Box
              boxClass="box-text-wrapper"
              heading={homePageData.box2Heading}
              subheading={homePageData.box2Description}
            />
          </Col>
          <Col className="box box-only-text" sm={12} md={6}>
            <Box
              boxClass="float-right box-text-wrapper"
              heading={homePageData.box3Heading}
              subheading={homePageData.box3Description}
            />
          </Col>
          <Col className="box blue-box" sm={12} md={6}>
            <Box
              boxClass="box-text-wrapper"
              imgSrc={HomeTalentImg}
              imgAlt="Talent"
              heading={homePageData.box4Heading}
              subheading={homePageData.box4Description}
            />
          </Col>
          {/* <Col className="padding-0" sm={12} md={12} lg={3}>
            <div className="container-fluid">
              <Row className="">
                <Col className="box-small" xs={12} md={6} lg={12}>
                  <Box
                    heading={homePageData.box4Heading}
                    desc={homePageData.box4Description}
                  />
                </Col>
                <Col className="box-small gray-box" xs={12} md={6} lg={12}>
                  <Box
                    heading={homePageData.box5Heading}
                    desc={homePageData.box5Description}
                  />
                </Col>
              </Row>
            </div>
          </Col> */}
        </Row>
      </div>
    </section>
  );
};

export default HomeBoxes;
