import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { transformationPageData } from '../../../content/transformationPageData';
import FormField from '../../atoms/FormField';
import FormFieldCheckbox from '../../atoms/FormFieldCheckbox';
import './style.scss';
import BoxImg from '../../../assets/img/contact-form-box.png';
import { postEmail } from './actions'


const ContactForm = () => {
    const { 
        contactFormHeader, 
        firstNameInput, 
        lastNameInput, 
        companyNameInput, 
        emailInput, 
        phoneInput, 
        servicesHeader, 
        service1Text, 
        service2Text, 
        service3Text, 
        textareaHeader, 
        textareaPlaceholder, 
        contactDateHeader, 
        btnText,
        successMsg,
        errorMsg } = transformationPageData;

    const [status, setStatus] = useState('initial');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('');
    const [service1, setService1] = useState(false);
    const [service2, setService2] = useState(false);
    const [service3, setService3] = useState(false);
    const [services, setServices] = useState([]);
    const [dateToContact, setDateToContact] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [messageError, setMessageError] = useState(false);

    useEffect(() => {
        if(status === 'success') {
            setFirstname('');
            setLastname('');
            setCompanyName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setServices([]);
            setService1(false);
            setService2(false);
            setService3(false);
            setDateToContact('');
        }
    }, [status])

    const handleSubmit = (data) => {
        setStatus('success')
        postEmail(data)
            .then(response => {
                const { status } = response.data;
                setStatus(status)
                setMessageError(false)
                setMessageSent(true)
                setStatus('initial')
            })
            .catch(error => {
                setStatus('error');
                setMessageSent(false)
                setMessageError(true)
            })
    }

    const onChange1 = (e) => {
        setService1(!service1);
        setServices(services.concat(e.target.previousElementSibling.value));
    }
    const onChange2 = (e) => {
        setService2(!service2);
        setServices(services.concat(e.target.previousElementSibling.value));
    }
    const onChange3 = (e) => {
        setService3(!service3);
        setServices(services.concat(e.target.previousElementSibling.value));
    }

    return (
        <div className='contact-form-wrapper'>
            <p className='contact-form-header'>{contactFormHeader}</p>
            <Form onSubmit={(event) => { 
                    event.preventDefault();
                    handleSubmit({firstname, lastname, companyName, email, phone, services, message, dateToContact})
                }}
                className='contact-form padding-tb-big'
            >
                <FormField 
                    id= 'firstname'
                    inputWrapper= 'firstnameInput'
                    value= {firstname}
                    errorMessage = 'test'
                    control={{
                        placeholder: `${firstNameInput}`,
                        required: true, 
                        onChange: (event) => setFirstname(event.target.value)
                    }}
                />
                <FormField 
                    id='lastname'
                    inputWrapper= 'lastnameInput'
                    value={lastname}
                    errorMessage = 'test'
                    control={{
                        placeholder: `${lastNameInput}`,
                        required: true, 
                        onChange: (event) => setLastname(event.target.value)
                    }}
                />
                <FormField 
                    id='companyname'
                    inputWrapper= 'companynameInput'
                    value={companyName}
                    control={{
                        placeholder: `${companyNameInput}`,
                        onChange: (event) => setCompanyName(event.target.value)
                    }}
                />
                <FormField 
                    id='email'
                    inputWrapper= 'emailInput'
                    value={email}
                    errorMessage = 'test'
                    control={{
                        placeholder: `${emailInput}`,
                        required: true, 
                        type: 'email',
                        onChange: (event) => setEmail(event.target.value)
                    }}
                />
                <FormField 
                    id='phone'
                    inputWrapper= 'phoneInput'
                    value={phone}
                    control={{
                        placeholder: `${phoneInput}`,
                        onChange: (event) => {
                            if (!isNaN(Number(event.target.value))) {
                                setPhone(event.target.value)
                            }
                        }
                    }}
                />
                <p className='services-header'>{servicesHeader}</p>
                <FormFieldCheckbox
                    id='service1'
                    label={service1Text}
                    value={service1Text}
                    checked={service1}
                    clickEvent={onChange1}
                />
                <FormFieldCheckbox
                    id='service2'
                    label={service2Text}
                    value={service2Text}
                    checked={service2}
                    clickEvent={onChange2}
                />
                <FormFieldCheckbox 
                    id='service3'
                    label={service3Text}
                    value={service3Text}
                    checked={service3}
                    clickEvent={onChange3}
                />
                <FormField 
                    id='message'
                    label={textareaHeader}
                    value={message}
                    inputWrapper= 'textareaInput'
                    control={{
                        as: 'textarea', 
                        placeholder: `${textareaPlaceholder}`,
                        rows: 5,
                        onChange: (event) => setMessage(event.target.value)
                    }}
                />
                <FormField 
                    id='dateToContact'
                    inputWrapper= 'contactDateInput'
                    value={dateToContact}
                    control={{
                        placeholder: `${contactDateHeader}`,
                        onChange: (event) => setDateToContact(event.target.value)
                    }}
                />
                {(messageSent && <p className='server-response'>{successMsg}</p>) || (messageError && <p className='server-response'>{errorMsg}</p>)}
                <button type='submit' className='submit-btn'>
                    {btnText}
                </button>
            </Form>
            <img src={BoxImg} alt='' className='contact-form-box' />
        </div>
    )
}

export default ContactForm;
