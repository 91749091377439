import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss';

const NotFound = (props) => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>Oops! Nothing was found</h2>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p> 
        <Link to="/">Return to homepage</Link>
      </div>
    </div> 
  )
}

export default NotFound;