import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.scss';

import Office from '../../components/atoms/Office';
import Square from '../../components/atoms/Square';
import Heading from '../../components/atoms/Heading';
import ContactImg from '../../assets/img/Contact-us-image.png';
import ContactContent from '../../components/widgets/ContactContent';

import { contactPageData } from '../../content/contactPageData';

const Contact = props => {
  return (
    <>
      <section className="contact-section padding-t-small padding-b-big">
        <Row className="margin-0">
          <Col lg={{ span: 6, order: 2 }} md={12} className="contact-image-div">
            <img src={ContactImg} alt="Contact" />
          </Col>
          <Col
            className="contact-section-info"
            xl={{ span: 4, offset: 2 }}
            lg={{ span: 5, order: 1, offset: 1 }}
          >
            <div className="my-auto">
              <Heading
                class="contact-section-heading padding-b-thin"
                heading={contactPageData.heading}
                subheading={contactPageData.subheading}
                email={contactPageData.email}
                paragraph={contactPageData.paragraph}
                subheadingClass='subheading-contact'
              />
              <div>
                <Square class="contact-section-yellow-square" />
                <Office offices={contactPageData.offices} />
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <ContactContent />
    </>
  );
};

export default Contact;
