import React from 'react';
import { Form } from 'react-bootstrap';

const FormField = (props) => {
    const { control, id, label, value, inputWrapper } = props
    return (
        <Form.Group controlId={id} className={inputWrapper}>
            <Form.Label>{label}</Form.Label>
            <Form.Control value={value} {...control}/>
        </Form.Group>
    )
}

export default FormField;
