import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';

import Heading from '../../atoms/Heading';
import { transformationPageData } from '../../../content/transformationPageData';

const TransformationBanner = () => {
  return (
    <section className="padding-tb-big transformation-banner">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={12} className="transformation-banner-text">
            <div className="padding-side-med">
              <Heading
                heading={transformationPageData.heading}
                subheading={transformationPageData.subheading1}
                class="transformation-heading"
              />
              <p>{transformationPageData.subheading2}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TransformationBanner;
