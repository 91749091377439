import React from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

import { homePageData } from '../../../content/homePageData';
import Square from '../../atoms/Square';
import HomeDottedImg from '../../../assets/img/Home-dotted.png';

export const Info = () => {
  return (
    <section className="padding-tb-big info-section">
      <div className="container-fluid padding-tb-big">
        <Row className="align-items-center">
          <Col xs={{ offset: 1, span: 11 }} xl={{ offset: 2, span: 7 }} className="info-content-wrapper">
            <Square class="home-info-yellow-square" />
            <div className="padding-t-med info-text">
              <p className="info-heading">{homePageData.infoSectionHeading}</p>
              <p className="info-about">
                {homePageData.infoSectionDesc}
              </p>
            </div>
          </Col>
          <Col xl={3} className="padding-0">
            <img
              className="info-dotted-img"
              src={HomeDottedImg}
              alt="Dotted box"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Info;
