export const peoplePageData = {
  heading: "Our People",
  subheading: "People are what matter most",
  bannerText: "",
  bannerDescriptionText:
    "In the world of business, things can change at the drop of a hat. As experienced professionals, it is our job to adapt and remain flexible on a daily basis to better serve our clients.",
  employees: [
    // {
    //   name: 'Jeff Lam',
    //   position: 'Cofounder and Managing Partner',
    //   paragraph1:
    //     'Jeff Lam is the co-founder of DigitalHuri, a bespoke consulting company that help companies in software development and digital transformation.  Before starting DigitlaHuri, Jeff has been a lifelong consultant working with some of the best-known brands in the world like Toyota, Lexus, Mazda, Warner Bros, Farmers Insurance, Wells Fargo, and Disney.',
    //   paragraph2:
    //     "Jeff's passion for consulting can be traced back to his youth.  Whether it was helping optimize his Dad's small business on the weekends when he was in high school or interning at multiple startups while in college, Jeff always sought out business problems and try to solve them.",
    //   paragraph3:
    //     'Fast forward 15 years later, Jeff is still seeking out business problems, only this time the problems are much larger and complex.  Over the years Jeff has sharpened his skills and met some talented people along the way with the same passion for solving business problems.',
    //   paragraph4:
    //     'Jeff feels like DigitalHuri is taking the culmination of his consulting experience and marrying it with the agency and technology worlds, to get the best in breed company that delivers a high-quality product.',
    //   picture: '/img/jeff.png',
    // },
    {
      name: "Greg Murch",
      position: "Executive Director/GM",
      paragraph1:
        "Greg has over 25 years of experience in technology, leadership, and strategic planning. Starting off as UI designer and front-end developer, Greg quickly transitioned to management, delivering large scale projects across multiple industries and delivery formats for major clients such as Toshiba, Nissan, Mitsubishi, The Rock & Roll Hall of Fame, United States Air Force, PacifiCare, Ameriquest Mortgage, WorldVision, Florida Marlins, Mazda, Toyota and many more.",
      paragraph2:
        "Prior to Company A, Greg built and led the Interactive Marketing division for Hyundai Autoever and oversaw all consumer facing marketing and telematics applications and systems.",
      paragraph3:
        "Greg leverages his experience in business strategy, process improvement, and operational management to ensure Company A is able to exceed clients’ expectations whilst helping them find the optimal solution to meet their needs.",
      picture: "/img/People-greg.jpg",
    },
    {
      name: "Mario Terrades",
      position: "Agile Practice Lead",
      paragraph1:
        "Mario began his technology career as a front-end developer over 10 years ago, in Miami, Florida. He was a hybrid employee where he learned QA while doing his development work. He relocated to Orange County California where he began his transition to automation engineer. While developing robust automation tests at KIA/Hyundai, he found a new passion for Agile. He began to incorporate scrums best practices within the organization, and projects started to take shape and were put on a path to be successful. Eliminating many anti-patterns existing within the scrum team, projects and team communication started making a turn for the better. During major application upgrades his scrum knowledge made the application transitions successful.",
      paragraph2:
        "Joining Company A, he brought his scrum expertise to the table. He has worked on several projects with Company A implementing his scrum methodology. The relationships built between stakeholders, product owners, and development teams is unmatched. The scrum practices Mario has implemented within our internal teams has become the model used by our Toyota partners. Currently, Certified Scrum Professional, and an aspiring Scrum Trainer, he continues to pave the way with all our existing and upcoming projects. Mario has proven the benefits of scrum and how adapting scrum the right way can save our clients time and money.",
      picture: "/img/People-mario.jpg",
    },
  ],
  peoplePeekHeading: "Our People know how to “Huri.”",
  peoplePeekText1:
    "The DigitalHuri team is a widely diverse, highly dedicated bunch of smart, passionate, dynamic individuals, located in Plano, Texas, Los Angeles, California and in Novi Sad, Serbia.",
  peoplePeekText2:
    "While each player on our team has a different story and a different goal, they all share a common belief and commitment to doing an outstanding job for every client, on every assignment. We're super proud of all of them, and happy to be able to provide opportunities that can make a difference in their lives.",
  peoplePeekText3: "",
  peoplePeekText4:
    "We're always looking for brilliant scrum product owners, scrum masters and development team members.",
};
