import React from 'react';
import './style.scss';

import Employee from '../../atoms/Employee';
import { peoplePageData } from '../../../content/peoplePageData';

const Employees = () => {
  return (
    <section className="padding-b-big padding-t-med">
      {peoplePageData.employees.map((employee, index) => (
        <section
          key={index}
          className="people-container padding-tb-med text-justify container"
        >
          <Employee
            name={employee.name}
            position={employee.position}
            about1={employee.paragraph1}
            about2={employee.paragraph2}
            about3={employee.paragraph3}
            about4={employee.paragraph4}
            src={employee.picture}
          />
        </section>
      ))}
    </section>
  );
};

export default Employees;
