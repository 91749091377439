import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';

import { transformationPageData } from '../../../content/transformationPageData';
import Box from '../../atoms/Box';

const TransformationBoxes = () => {
  return (
    <section className="transformation-boxes-section">
      <Container fluid>
        <Row>
          <Col xs={12} lg={6} className="transformation-text-box d-flex align-items-center">
          <div className="padding-side-med padding-tb-big tranformation-smaller-boxes">
              <Box
                desc={transformationPageData.box1Desc1}
              />
              <p>{transformationPageData.box1Desc2}</p>
            </div>
          </Col>
          <Col xs={12} lg={6} className="transformation-text-box d-flex align-items-center yellow-bg">
            <div className="padding-side-med padding-tb-big tranformation-smaller-boxes">
              <Box 
                heading={transformationPageData.box2Heading}
              />
              <ul className="box-list">
                {transformationPageData.box2List.map(transformation => (
                  <li key={transformation.item}>{transformation.item}</li>
                  ))
                }
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="padding-tb-big section-between-boxes">
            <p className="padding-tb-big text-between-boxes">{transformationPageData.textBetweenBoxes}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className="box-position-img">
          </Col>
          <Col xs={12} lg={8} className="transformation-text-box d-flex align-items-center">
            <div className="padding-side-med padding-tb-big">
              <Box
                heading={transformationPageData.box3Heading}
                desc={transformationPageData.box3Desc1}
              />
              <p className="font-weight-bold">{transformationPageData.box3Desc2}</p>
              <p>{transformationPageData.box3Desc3}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8} className="transformation-text-box d-flex align-items-center light-gray-bg">
            <div className="padding-side-med padding-tb-big">
              <Box
                heading={transformationPageData.box4Heading}
                desc={transformationPageData.box4Desc1}
              />
            </div>
          </Col>
          <Col xs={12} lg={4} className="box-invest-img">
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className="box-align-peeps-img">
          </Col>
          <Col xs={12} lg={8} className="transformation-text-box d-flex align-items-center">
            <div className="padding-side-med padding-tb-big">
              <Box
                heading={transformationPageData.box5Heading}
                desc={transformationPageData.box5Desc1}
              />
              <p>{transformationPageData.box5Desc2}</p>
              <p className="peeps-par font-weight-bold">{transformationPageData.box5Desc3}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TransformationBoxes;
