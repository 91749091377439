import React from 'react'

import DifferenceBanner from '../../components/widgets/DifferenceBanner';
import DifferenceBoxes from '../../components/widgets/DifferenceBoxes';
import DifferenceInfo from '../../components/widgets/DifferenceInfo';

const Difference = () => {
    return (
        <>
            <DifferenceBanner />
            <DifferenceBoxes />
            <DifferenceInfo />
        </>
    )
}

export default Difference;
