import React from 'react';
import Peek from '../../atoms/Peek';
import './style.scss';

import { transformationPageData } from '../../../content/transformationPageData';

const ContactPeek = () => {
    return (
        <section className="contact-peek-section padding-t-big">
            <div className="container d-flex justify-content-center">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <Peek
                            peekHeading={transformationPageData.peekHeading}
                            peekContent={transformationPageData.peekContent}
                            peekHeadingClass="contact-peek-heading"
                            peekContentClass="contact-peek-content"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactPeek;
