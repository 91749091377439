import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.scss';

import Heading from '../../atoms/Heading';
import Square from '../../atoms/Square';
import { homePageData } from '../../../content/homePageData';
import BannerPicture from '../../../assets/img/home-banner-img.png';


const HomeBanner = () => {
    return (
        <section className="container-fluid padding-t-small home-banner">
            <Row>
                <Col xs={{ span: 12 }} lg={{ span: 6, order: 2}} className="banner-pic">
                    <img src={BannerPicture} alt="Focus" />
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6, order: 1}} className="padding-t-big">
                    <div className="home-banner-text-wrapper">
                        <Square class="home-banner-yellow-square" />
                        <Heading heading={homePageData.heading} class="home-banner-heading" />
                        <a href="/difference" className="home-banner-btn">{homePageData.bannerBtnText}</a>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default HomeBanner;
