import React from 'react';

const Box = props => {
  return (
    <div className={props.boxClass}>
      <img src={props.imgSrc} alt={props.imgAlt} />
      <h2 className="box-heading">{props.heading} <span>{props.subheading}</span></h2>
      <p>{props.desc}</p>
    </div>
  );
};

export default Box;
