import React from 'react';
import ContactForm from '../ContactForm';
import './style.scss';
import QuestionMark from '../../../assets/img/question-mark-form.png';

const ContactContent = () => {
    return (
        <section className='padding-tb-big contact-content-section'>
            <div className='container-fluid padding-b-big'>
                <div className='row'>
                    <div className='hide-sm-img col-md-3'>
                        <img src={QuestionMark} alt="" className='question-mark-contact' />
                    </div>
                    <div className='col-12 col-md-6'>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default ContactContent;
