export const differencePageData = {
    heading: 'Our Difference',
    bannerText1: 'The world is changing very fast. Businesses are getting disrupted faster than ever before. Any organization that is not in tune with the speed and composition of global digital trends is at a huge risk of becoming less relevant or even irrelevant.',
    bannerText2: 'To make matters even harder, is all the hype around Artificial Intelligence, IoT, Mixed Reality, etc. It can be difficult to understand how these emerging technologies can provide measurable ROI for your business.',
    bannerText3: 'Our recommendation to clients is to keep things simple and start with high impact projects with a strong likelihood of success. Digital transformation doesn’t need to be an overwhelming change in product, business model, or paradigm. At its core, transformation is about identifying fundamental parts of your business and cultivating improvement with digital technology. Think enhanced rather than changed. Because we all know that there are people in your building that don’t like to hear the word “change.” Hahaha.',
    bannerText4: "To move your firms current state to a state of digital transformation, you need people that are digital natives. You also need digital transformers that are both theoretical and practical. Strategists and worker bees. Not one without the other. That's what makes us different.",
    bannerText5: 'And finally, you also need a digital transformation partner that is truthful.',
    bannerText6: "Our promise to you is that we'll get you as far as possible, based first on the tool set that you have.",
    lastSectionText1: 'The goal of your digital transformation should be to enhance your digital customer experience while also driving agility and efficiency through digital operational excellence. This is a tough challenge where few firms have been successful.',
    lastSectionText2: 'We are not digital coaches that only teach the framework. We are project delivery experts that will bring business value.',
    box1Heading: 'We place a heavy emphasis on customer outcomes to unlock business opportunities. We can help your company to extend existing business opportunities with digital technology.',
    box2Heading: 'Technology is easy, but people are hard when it comes to change. We will work with all stakeholders to focus on the culture gaps needed to establish transformation.',
    box3Heading: 'We will help you establish the right organization structure to kick-start digital transformation.',
    box4Heading: "We will start by identifying the core digital skills needed to establish transformation. Once determined we'll bring the right skills on board by recruiting internal and external talent.",
    box5Heading: 'Which of the following best describes your firm’s digital transformation journey?',
    box5list: [
        {
            item: 'Currently underway.'
        },
        {
            item: 'Investigating.'
        },
        {
            item: 'Improving but not transforming.'
        },
        {
            item: 'Completed.'
        },
        {
            item: 'Nowhere. ( uh oh!)'
        }
    ],
    box5Desc1: "Regardless of where you are in your digital transformation we can help you. Give us a call and let's chat about it.",
}