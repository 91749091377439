import React from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

import Heading from '../../atoms/Heading';
import { differencePageData } from '../../../content/differencePageData';
import DifferenceBannerImg from '../../../assets/img/Header-difference.jpg';

const DifferenceBanner = () => {
  return (
    <section className="padding-t-small difference-banner">
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col
            xs={12}
            lg={{ span: 6, order: 2 }}
            className="diff-img-container"
          >
            <img src={DifferenceBannerImg} alt="Focus" />
          </Col>
          <Col
            xs={12}
            lg={{ span: 5, offset: 1, order: 1 }}
            className="my-auto text-justify"
          >
            <Heading
              heading={differencePageData.heading}
              class="diff-heading"
            />
            <p>{differencePageData.bannerText1}</p>
            <p>{differencePageData.bannerText2}</p>
            <p>{differencePageData.bannerText3}</p>
            <p>{differencePageData.bannerText4}</p>
            <p>{differencePageData.bannerText5}</p>
            <p>{differencePageData.bannerText6}</p>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default DifferenceBanner;
