import React from 'react';
import './style.scss';
import { Row, Col, Container } from 'react-bootstrap';

// import Logo from '../../../assets/img/logo.png';
import { differencePageData } from '../../../content/differencePageData';

const DifferenceInfo = () => {
    return (
        <section className="difference-info-section padding-tb-big">
            <Container>
                <Row>
                    {/* <Col xs={12} className="text-center">
                        <img className="padding-tb-small difference-logo" src={Logo} alt="Logo" />
                    </Col> */}
                    <Col xs={12} className="text-center text-justify">
                        <p>{differencePageData.lastSectionText1}</p>
                        <p className="font-italic font-weight-bold diff-info-text">{differencePageData.lastSectionText2}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DifferenceInfo;
