import React from 'react';
import TransformationBanner from '../../components/widgets/TransformationBanner';
import TransformationBoxes from '../../components/widgets/TransformationBoxes';
import ContactPeek from '../../components/widgets/ContactPeek';
import ContactContent from '../../components/widgets/ContactContent';

const Transformation = () => {
  return (
    <>
      <TransformationBanner />
      <TransformationBoxes />
      <ContactPeek />
      <ContactContent />
    </>
  );
};

export default Transformation;
