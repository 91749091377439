import React from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

import Heading from '../../atoms/Heading';
import { peoplePageData } from '../../../content/peoplePageData';
import PeopleHeader from '../../../assets/img/People-header.jpg';
import Square from '../../atoms/Square';

const PeopleBanner = () => {
  return (
    <section className="padding-t-small">
      <Row className="margin-0">
        <Col
          lg={{ span: 6, order: 2 }}
          md={12}
          className="people-banner-img-container"
        >
          <img src={PeopleHeader} alt="We are" />
        </Col>
        <Col
          xl={{ span: 4, offset: 2 }}
          lg={{ span: 5, order: 1, offset: 1 }}
          className="d-flex"
        >
          <div className="my-auto">
            <Heading
              heading={peoplePageData.heading}
              subheading={peoplePageData.subheading}
              class="people-banner-heading"
            />
            <Square class="people-banner-square" />
            <div className="banner-text-wrapper padding-b-small">
              <p className="font-weight-bold">{peoplePageData.bannerText}</p>
              <p className="light-gray-font">
                {peoplePageData.bannerDescriptionText}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default PeopleBanner;
