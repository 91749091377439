import React from 'react'

import Header from '../components/atoms/Header';
import Footer from './../components/atoms/Footer/index';

const Layout = (props) => {
  return (
    <>
      <Header pathname={props.location.pathname} />
        {props.children}
      <Footer/>
    </>
  )
}

export default Layout;