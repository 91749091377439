import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import Video from '../../atoms/Video';
import './style.scss';
import { homePageData } from '../../../content/homePageData';

const VideoSection = () => {
    const { videoHeading1, videoSrc1, videoTitle1, videoHeading2, videoTitle2, videoSrc2 } = homePageData;
    return (
        <section className="padding-tb-big yt-homepage-section">
            <Container fluid>
                <Row>
                    <Col xs={12} md={6}>
                        <Video 
                            heading={videoHeading1} 
                            headingClass='video-heading' 
                            iframeClass='yt-video' 
                            videoContainer='yt-video-container'
                            videoWrapper='yt-video-wrapper'
                            videoTitle={videoTitle1}
                            videoSrc={videoSrc1} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Video 
                            heading={videoHeading2} 
                            headingClass='video-heading' 
                            iframeClass='yt-video' 
                            videoContainer='yt-video-container'
                            videoWrapper='yt-video-wrapper'
                            videoTitle={videoTitle2}
                            videoSrc={videoSrc2} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default VideoSection;
