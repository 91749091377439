import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
      <footer className="padding-t-med">
          <Container fluid>
              <Row>
                <Col md={8} xs={12}>
                    <div className="footer-links">
                        <Link to="/">Home</Link>
                        <Link to="/difference">Difference</Link>
                        <Link to="/transformation">Transformation</Link>
                        <Link to="/people">People</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                </Col>
                <Col md={4} xs={12}>
                    <div className="mail-link">
                        <a href="mailto:mgmt@digitalhuri.com">mgmt@digitalhuri.com</a>
                    </div>
                </Col>
              </Row>
          </Container>
      </footer>
    )
}
export default Footer;
