import React from 'react'

const Peek = (props) => {
    return (
        <>
            <p className={props.peekHeadingClass}>{props.peekHeading}</p>
            <p className={props.peekContentClass}>{props.peekContent}</p>
            <a href="/contact" className={props.peekBtnClass}>{props.peekBtnText}</a>
        </>
    )
}

export default Peek;
