export const homePageData = {
    heading: 'We transform companies to digital operational excellence.',
    bannerBtnText: 'get to know us better',
    infoSectionHeading: 'Expect the unexpected in a digital world.',
    infoSectionDesc: 'Sure. But please not everyday!',
    boxesHeading: 'Our difference lies in an unwavering focus on five.',
    box1Heading: 'The “Gig Economy”',
    box1Description: 'shouldn’t mean you augment your staff with people who think “it’s just a gig.”',
    box2Heading: 'Marketing says',
    box2Description: '“IT doesn’t get it.” IT says “marketing doesn’t get it.” HR says the company Culture is awesome. Hmmm...',
    box3Heading: 'Geeks by the hour,',
    box3Description: 'week or month. Our people can be nerdy, but believe us, that’s what you want.',
    box4Heading: 'Are you digital first?',
    box4Description: 'Really, Seriously?',
    digitalHeading: 'Why the need for DigitalHuri?',
    digitalText1: 'We believe the period of the customer means that every business has to become a digital business. The goal of your digital transformation should be to enhance your digital customer experience while also driving agility and efficiency through digital operational excellence. This is a tough challenge where few firms have been successful and many more will fail along the way. We are your digital transformation partner that can help with this challenge because we were born out of a digital landscape.',
    digitalText2: 'Our team are professionals with cross-functional experience in creative, tech, and marketing. We are not digital coaches that only teach the framework. We are project delivery experts that bring business value.',
    videoHeading1: 'Meet Digital Huri',
    videoSrc1: 'https://www.youtube-nocookie.com/embed/mVDJDHZVyBg?disablekb=1&modestbranding=1&rel=0',
    videoTitle1: 'Digital Transformation',
    videoHeading2: 'Our case study',
    videoSrc2: 'https://www.youtube-nocookie.com/embed/n5Zp6MQTQik?disablekb=1&modestbranding=1&rel=0',
    videoTitle2: 'Case study'
}