import React from 'react';
import './style.scss'

const Office = (props) => {
  const { offices } = props
  return(
    <div className="office">
      <div>
        {
          offices.map(office => (
            <div key={office.name}>
              <strong>{office.name}:</strong>
              <p>{office.address}<br/> {office.email != null ? (<span>E-mail: {office.email}</span>) : null}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Office;