import React from 'react';

import { Row, Col } from 'react-bootstrap';

const Employee = props => {
  return (
    <>
      <Row className="employee-wrapper">
        <Col xs={12} md={5} lg={5}>
          <img className={props.peopleImg} src={props.src} alt={props.alt} />
        </Col>
        <Col xs={12} md={7} lg={7} className="padding-t-med">
          <h3 className={props.peopleName}>{props.name}</h3>
          <p className={props.positionClass}>{props.position}</p>
          <div className={props.peopleAbout}>
            <p>{props.about1}</p>
            <p>{props.about2}</p>
            <p>{props.about3}</p>
            <p>{props.about4}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Employee;
