import React from 'react'

const Heading = (props) => {
    return (
        <div className={props.class}>
            <h1>{props.heading}</h1>
            <p className={props.subheadingClass}>{props.subheading}</p>
            <a href={`mailto:${props.email}`}>{props.email}</a>
            <p>{props.paragraph}</p>
        </div>
    )
}

export default Heading;
