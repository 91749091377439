import React from 'react';
import './style.scss';
import { Row, Col } from 'react-bootstrap';

import { homePageData } from '../../../content/homePageData';
import questionMarkImg from '../../../assets/img/question-mark.png';

const DigitalTranformation = () => {
    return (
        <section className="digital-section">
            <div className="container">
                <Row>
                    <Col>
                        <div className="text-center">
                            <img src={questionMarkImg} alt="question-mark" className="question-mark-img" />
                        </div>
                        <div className="padding-b-big digital-text-wrapper">
                            <p className="digital-heading">{homePageData.digitalHeading}</p>
                            <p>{homePageData.digitalText1}</p>
                            <p>{homePageData.digitalText2}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default DigitalTranformation;
