import React from 'react';

import PeopleBanner from '../../components/widgets/PeopleBanner';
import Employees from '../../components/widgets/Employees';
import PeoplePeek from '../../components/widgets/PeoplePeek';

const People = () => {
    return (
        <>
            <PeopleBanner />
            <Employees />
            <PeoplePeek />
        </>
    )
}

export default People;
