export const contactPageData = {
    heading: 'email us',
    subheading: 'WE’D LOVE TO HEAR FROM YOU',
    email: 'mgmt@digitalhuri.com',
    paragraph: '(we promise to get back to you within 1 business day at the latest)',
    offices: [
        {
            name: 'Orange County Office (HQ)',
            address: '2850 Red Hill Ave Suite 140, Santa Ana, CA 92705'
        },
        {
            name: 'Dallas Office',
            address: '5345 Town Square Drive, Suite #230, Plano, Texas 75024'
        }
    ]
}