import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';

import { peoplePageData } from '../../../content/peoplePageData';

const PeoplePeek = () => {
    return (
        <section className="padding-tb-big light-gray-bg people-peek-section">
            <Container fluid>
                <Row>
                    <Col>
                            <p className="people-peek-heading">{peoplePageData.peoplePeekHeading}</p>
                            <p>{peoplePageData.peoplePeekText1}</p>
                            <p>{peoplePageData.peoplePeekText2}</p>
                            <p>{peoplePageData.peoplePeekText3}</p>
                            <p>Want to join us? Send us an <a href="/contact">email.</a></p>
                            <p>{peoplePageData.peoplePeekText4}</p>
                        
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PeoplePeek;
