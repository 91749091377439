import React from 'react'

const Video = (props) => {
    const { heading, videoSrc, headingClass, iframeClass, videoAlt, videoWrapper, videoContainer, videoTitle } = props;
    return (
        <>
            <p className={headingClass}>{heading}</p>
            <div className={videoContainer}>
                <div className={videoWrapper}>
                    <iframe title={videoTitle} className={iframeClass} alt={videoAlt} src={videoSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </>
    )
}

export default Video;
