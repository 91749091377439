import React from 'react';

import HomeBanner from '../../components/widgets/HomeBanner';
import HomeBoxes from '../../components/widgets/HomeBoxes';
import Info from '../../components/widgets/Info';
import DigitalTranformation from '../../components/widgets/DigitalTransformation';
import VideoSection from '../../components/widgets/VideoSection';

const Home = () => {
    return (
        <>
            <HomeBanner />
            <HomeBoxes />
            <VideoSection />
            <Info />
            <DigitalTranformation />
        </>
    )
}

export default Home;