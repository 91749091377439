import React from 'react';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';

import { differencePageData } from '../../../content/differencePageData';
import Box from '../../atoms/Box';
import StrategyElement from '../../../assets/img/Strategy-elements.png';
import TalentElement from '../../../assets/img/Talent.png';
import TechnologyElement from '../../../assets/img/technology.png';

const DifferenceBoxes = () => {
  return (
    <section className="diff-boxes-section">
      <Container fluid>
        <Row>
          <Col xs={12} lg={{ span: 5, order: 1 }} className="diff-yellow-box1 yellow-bg">
            <div className="padding-t-big">
              <img src={StrategyElement} alt="Strategy" />
            </div>
          </Col>
          <Col xs={12} lg={{ span: 7, order: 2 }} className="diff-text-box">
            <div className="padding-tb-big padding-side-med">
              <Box
                heading={differencePageData.box1Heading}
                desc={differencePageData.box1Desc1}
              />
              <p>{differencePageData.box1Desc2}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 7, order: 2 }} className="technology-diff-box">
            <div>
            </div>
          </Col>
          <Col xs={12} lg={{ span: 5, order: 1 }} className="diff-text-box">
            <div className="padding-tb-big padding-side-med">
              <Box
                heading={differencePageData.box2Heading}
                desc={differencePageData.box2Desc1}
              />
              <p>{differencePageData.box2Desc2}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 5, order: 1 }} className="diff-blue-box blue-bg">
            <div className="padding-tb-med">
              <img src={TalentElement} alt="Talent" />
            </div>
          </Col>
          <Col xs={12} lg={{ span: 7, order: 2 }} className="d-table diff-text-box">
            <div className="padding-tb-big padding-side-med d-table-cell align-middle">
              <Box
                heading={differencePageData.box3Heading}
                desc={differencePageData.box3Desc1}
              />
              <p>{differencePageData.box3Desc2}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 7, order: 2 }} className="identifying-diff-box">
            <div>
            </div>
          </Col>
          <Col xs={12} lg={{ span: 5, order: 1 }} className="diff-text-box">
            <div className="padding-tb-big padding-side-med">
              <Box
                heading={differencePageData.box4Heading}
                desc={differencePageData.box4Desc1}
              />
              <p>{differencePageData.box4Desc2}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 5, order: 1 }} className="diff-yellow-box2 yellow-bg">
            <div>
              <img src={TechnologyElement} alt="Technology" />
            </div>
          </Col>
          <Col xs={12} lg={{ span: 7, order: 2 }} className="diff-text-box">
            <div className="padding-tb-big padding-side-small">
              <Box
                heading={differencePageData.box5Heading}
              />
              <ul className="diff-box-list">
                {differencePageData.box5list.map(diff => (
                  <li key={diff.item}>{diff.item}</li>
                  ))
                }
              </ul>
              <p>{differencePageData.box5Desc1}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DifferenceBoxes;
