import React, { useEffect } from 'react';
import { Router as ReactRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import MainLayout from './layout';
import Home from '../pages/Home';
import Difference from '../pages/Difference';
import Transformation from '../pages/Transformation';
import People from '../pages/People';
import Contact from '../pages/Contact';
import NotFound from '../pages/404';

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/difference", name: "Difference", component: Difference },
    { path: '/transformation', name: 'Transformation', component: Transformation },
    { path: '/people', name: 'People', component: People },
    { path: '/contact', name: 'Contact', component: Contact },
    { path: '/*', name: '404', component: NotFound }
]

const hist = createBrowserHistory();

const Router = () => {
    useEffect(() => {
        hist.listen(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        })
    }, [])
  
    return (
      <ReactRouter history={hist} >
          <Switch>
            {routes.map((prop, key) => {
              let Page = prop.component
              return <Route 
                exact
                path={prop.path}
                render={
                  (matchProps) => (
                    <MainLayout {...matchProps}>
                      <Page {...matchProps} />
                    </MainLayout>
                  )
                } 
                key={key} 
              />;
            })}
          </Switch>
      </ReactRouter>
)}
  
export default Router;
