import React from 'react';
import { Form } from 'react-bootstrap';

const FormFieldCheckbox = (props) => {
    const { id, label, value, checked, clickEvent } = props;
    return (
        <Form.Group className='checkboxgroup'>
            <Form.Label>{label}</Form.Label>
            <input className="form-control" name='service' id={id} value={value} type='checkbox' checked={checked} disabled />
            <span className="checkmark" onClick={clickEvent}></span>
        </Form.Group>
    )
}

export default FormFieldCheckbox;
