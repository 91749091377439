export const transformationPageData = { 
    heading: 'Transform your digital.',
    subheading1: 'End to end.',
    subheading2: 'Little “dustings” of digital transformation won’t cut it.',
    box1Desc1: 'Digital transformation is on a spectrum that affects every organization and every industry. Any organization that is not in tune with the speed and composition of global digital trends is at a huge risk of becoming less relevant or even irrelevant. Success can often depend on how well and how fast your leaders and management respond to the ever-changing business environment, reimagining how the company can be rebuilt around innovations, disruptions and digitalisation.',
    box1Desc2: 'We help businesses to evolve with the market and increase value. Regardless of what stage you are at with your digital transformation, you need the right partner to move you from vision to execution.',
    box2Heading: 'We will help your business to succeed with its transformation by achieving the following:',
    box2List: [
        {
            item: '- Frequent communication through traditional and digital.'
        },
        {
            item: '- Ensuring that you have the right digital people in place'
        },
        {
            item: '- Building the right capabilities for the employees of the future'
        },
        {
            item: '- Giving the day to day tools a digital upgrade'
        }
    ],
    box3Heading: '1. Position Digital Transformation as a Marathon, not a Sprint',
    box3Desc1: 'Too many organizations treat digital transformation as a sprint project with a distinct beginning and a finite end. And when it’s complete you’re done! Yay! Partay!!',
    box3Desc2: 'Really? You’re done?',
    box3Desc3: 'Sure, if customer expectations stop rising and technology companies stop innovating, that’s a fair statement to make. But in reality, leading futurists believe we’ll see more change in the next 15 years than we’ve seen in the past 300, with much of that change being fueled by emerging technologies. The clients who win at transformation see it not as a one-time task, but as an ongoing program with a series of new projects year after year. Their approach focuses on incremental change over time rather than a single big bang, and they’re willing to invest significant capital in innovation and exploration of new technologies that raise the bar for customer experiences.',
    box4Heading: '2. Invest in Strategy and focus on the fundamentals',
    box4Desc1: 'What’s the use of running if you’re not on the right road? The foundational investments of digital transformation are extremely important, whether it’s technology platforms, experience models, or operations. A digital strategy will help maximize ROI in these foundational investments, recommend a series of phases and sequencing of projects, and ensure the multiple initiatives along your digital transformation journey are aligned to a higher-level vision and purpose. When things get tough – and they will – a solid strategy will serve as a reminder as to why you’re doing all of this in the first place.',
    box5Heading: '3. Align Your Peeps.',
    box5Desc1: 'Let’s be honest, digital transformation initiatives are expensive and require year-over-year commitment. They require buy-in from every department, executive, manager, and employee. Project failures will happen along the way, so a foundation of organizational resilience is key.',
    box5Desc2: 'Transformational effort struggle to succeed without comprehensive sponsorship and directive from the entire C-suite. Poor leadership and misalignment can quickly immobilize an organization. Sometimes smart people make poor decisions because they feared that taking a risk or working differently would mean losing their job. This lack of alignment is probably why 15% of companies are still contemplating starting their digital transformation journey.',
    box5Desc3: 'So align your people and get buy-in.',
    peekHeading: "So what's your CX strategy?",
    peekContent: "Or don't you have one? (:",
    textBetweenBoxes: 'Regardless of industry or company size, we’ve found that there are 3 major lessons to learn from our clients who’ve been winning at their digital transformation.',
    contactFormHeader: 'Let us help you!',
    firstNameInput: 'First Name',
    lastNameInput: 'Last Name',
    companyNameInput: 'Company name',
    emailInput: 'Email',
    phoneInput: 'Phone',
    servicesHeader: "Service area you're most interested in:",
    service1Text: 'Technical Assessment',
    service2Text: 'Staff Aug',
    service3Text: 'Digital Transformation',
    textareaHeader: 'Description',
    textareaPlaceholder: 'optional but the more you can tell us the better we can prepare for you',
    contactDateHeader: 'When would you like us to contact you?',
    btnText: 'email us',
    successMsg: 'Your message was successfully sent. Thank You for contacting us!',
    errorMsg: 'Oops message not sent!'
}